<template>
	<section v-if="good">
		<div class="detailBox">
			<div class="imgBox">
				<ul>
					<li>
						<img v-if="!isPlay" src="https://qpyx-rlsb.oss-cn-shanghai.aliyuncs.com/2024/09/13/34a6fd3168854cbd8effcd32ab7c2299.png" />
						<iframe v-else width="860" height="650" frameborder="0" :src="playGameUrl"></iframe>
					</li>
				</ul>
			</div>
			<!-- <div class="infoBox"> -->
				<!-- <p class="stitle">CHANGSHA QIPA DOUDOU</p>
				<h1>{{good.title}}</h1> -->
				<!-- <div class="priceBox">
					<p class="price">
						<span class="original">¥{{good.originalPrice}} CNY</span>
						<span class="onsale">¥{{good.presentPrice}} CNY</span>
					</p>
				</div>
				<div>
					<el-form
					    label-position="top"
					    label-width="100px"
					    :model="form"
					    style="max-width: 460px"
					  >
					    <el-form-item label="数量">
							<el-input-number v-model="form.num" :min="1" :max="good.maxNum" size="large" />
					    </el-form-item>
					  </el-form>
				</div>
				<div class="btns">
					<el-button @click="addCart">加入购物车</el-button>
					<el-button type="primary" @click="addBuy">立即购买</el-button>
				</div> -->
				<!-- <div class="description" v-html="good.details"></div> -->
				<!-- <div v-if="playGameUrl" class="downloadBtn"><a :href="playGameUrl" target="_blank" download>立即试玩</a></div> -->
				<!-- <div class="accordion">
					<el-collapse v-model="activeName" accordion>
					  <el-collapse-item name="1">
						  <template #title>
							  <el-icon size="14" style="margin-right: 5px;"><Van /></el-icon>
							退发货
						  </template>
						<div>
						  您购买后将立即通过您的电子邮件发送
						</div>
					  </el-collapse-item>
					</el-collapse>
				</div> -->
			<!-- </div> -->
		</div>
	</section>
	<section>
		<div v-if="!isPlay" class="downloadBtn"><a href="javascript:;" @click="isPlay = true" >立即试玩</a></div>
		<div v-else class="downloadBtn"><a href="javascript:;" @click="isPlay = false" >返回</a></div>
		<!-- <div class="multiBox">
			<h3>免运费</h3>
			<p>如果您购买后直接通过电子邮件发送，则无运费</p>
		</div> -->
	</section>
	<section class="bg1">
		  <div class="listBox">
			  <h2 class="title" style="font-size: 2.4rem;">您可能会喜欢</h2>
			  <div class="listItem">
				  <ul>
					<template v-for="(item,index) in allList" :key="index">
						<li class="item" v-if="index < 1">
							<div class="imgBox">
								<router-link :to="'/products/detail/'+item.id">
									<img src="https://qpyx-rlsb.oss-cn-shanghai.aliyuncs.com/2024/09/13/34a6fd3168854cbd8effcd32ab7c2299.png" />
								</router-link>
								<!-- <span class="tag">강시</span> -->
							</div>
							<div class="desBox">
								<h3 class="title">
									<router-link :to="'/products/detail/'+item.id">
									<!-- {{item.title}} -->
									武道将魂-无限合宠刷充服
									</router-link>
								</h3>
								<p class="price">
									<!-- <span class="original">${{item.originalPrice}} CNY</span>
									<span class="onsale">${{item.presentPrice}} CNY</span> -->
									<router-link :to="'/products/detail/'+item.id"><span class="onsale">立即试玩</span></router-link>
								</p>
							</div>
						</li>
					</template>
				  </ul>
			  </div>
		  </div>
	</section>
</template>

<script setup>
	import { Van } from '@element-plus/icons-vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { ref, onMounted } from "vue";
	import { useRouter,useRoute } from 'vue-router'
	import { getGoodList,addCartGood } from '@/api/index'
	import useUserStore from '@/store/modules/user'
	
	let router = useRouter();
	let route = useRoute();
	
	const allList = ref([])
	const goodList = ref([])
	const form = ref({
		num: 1
	})
	const good = ref({})
	const activeName = ref('1')
	const isPlay = ref(false)
	const playGameUrl = ref('https://h5-web.7pa.com/login.html?g=3045&b=1')
	onMounted(()=>{
		getGoodList({ id: route.params.Id }).then((res)=>{
			goodList.value = res.rows
			if(goodList.value.length > 0){
				good.value = goodList.value[0]
			}
		})
		getAll()
	})
	const getAll = () => {
		getGoodList().then((res)=>{
			allList.value = res.rows
		})
	}
	const addCart = () => {
		const { id, state, presentPrice } = good.value
		form.value.goodsId = id
		form.value.state = state
		form.value.totalPrice = (presentPrice * form.value.num).toFixed(2)
		form.value.unitPrice = presentPrice
		form.value.userId = useUserStore().userid
		addCartGood(form.value).then((res) => {
			ElMessage({
			    message: 'Success',
			    type: 'success',
			})
			router.push('/cart')
			console.log(res)
		})
	}
	// 立即购买（暂无立即购买接口）
	const addBuy = () => {
		addCart()
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.downloadBtn{
		width: 100%;height: 4.5rem;line-height: 4.5rem;text-align: center;max-width: 18.75rem;
		margin: 0 auto 4rem;background: #000;cursor: pointer;
		a{
			display: inline-block;color: #fff!important;
			width: 100%;height: 100%;font-size: 1.25rem;
		}
	}
.detailBox{
	display: flex;max-width: 120rem;justify-content: center;
	margin: 0 auto;
	padding-top: 36px;padding-bottom: 12px;
	.imgBox{
		max-width: 80%;
		ul{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 2rem;
			padding: 0;
			grid-gap: 8px;
			li{
				width: calc(50% - 4px);font-size: 0;
				&:first-child{
					width: 100%;
				}
				img{
					width: 100%;
				}
			}
		}
	}
	
	.infoBox{
		padding: 0 0 0 4rem;box-sizing: border-box;
		max-width: 35%;font-size: 1.6rem;letter-spacing: 0.06rem;
		.stitle{
			font-size: 1rem;letter-spacing: 0.13rem;
		}
		
		.priceBox{
			margin: 1.5rem 0;
			.price{
				line-height: 24px;margin-top: 1rem;
				display: inline-flex;align-items: center;
				.original{
					display: inline-block;
					text-decoration: line-through;
					font-size: 1.5rem;
					color: rgba(18,18,18,0.75);margin-right: 1rem;
				}
				.onsale{
					color: rgb(18,18,18);
					font-size: 1.8rem;letter-spacing: .1rem;
				}
				.tag{
					padding: 0.05rem 1.2rem 0.06rem;margin-left: 1rem;
					display: inline-block;background: #0066CC;border-radius: 4rem;
					color: #fff;font-size: 1.2rem;
				}
			}
		}
		h1{
			font-size: 4rem;font-weight: 400;margin: 0;
		}
		.btns{
			width: 100%;
			.el-button{
				width: 100%;height: 4.5rem;border-radius: 0;
			}
			.el-button + .el-button{
				margin-left: 0;margin-top: 1rem;
			}
		}
		.description{
			margin: 2.5rem 0;
			line-height: calc(1 + 0.8 / 1.0);
			span{
				color: #a91313;
			}
		}
	}
}
.multiBox{
	max-width: 72rem;margin: 0 auto;
	padding: 36px 1.5rem 52px;font-size: 1.8rem;
	h3{
		font-weight: 400;margin: 0;
	}
	p{
		margin-top: 1rem;color: rgba(18,18,18,0.75);
		font-size: 1.6rem;
	}
}
</style>
